*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  min-height: 100svh;
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    scale: .8;
  }
}

@keyframes orbit {
  25% {
    transform: rotateZ(.25turn);
  }

  50% {
    transform: rotateZ(.5turn);
  }

  75% {
    transform: rotateZ(.75turn);
  }

  100% {
    transform: rotateZ(1turn);
  }
}

.loader {
  contain: strict;
  width: 100%;
  height: 100%;
  color: #fff;
  opacity: .8;
  mix-blend-mode: overlay;
  justify-content: center;
  align-items: center;
  animation: 4s fade-in;
  display: flex;
  position: absolute;
  inset: 0;
}

.loader:after {
  content: "";
  width: 2rem;
  height: 2rem;
  filter: drop-shadow(-2px -2px 4px #fff);
  background: radial-gradient(circle at 0 0, #fff, #0000 60%);
  border-radius: 100%;
}

.loader .spinner {
  --orbit-diameter: 6rem;
  --orbits-gap: calc(var(--orbit-diameter) / 4);
  top: calc(50% - var(--orbits-gap) / 2);
  height: var(--orbits-gap);
  position: absolute;
  transform: rotateX(75deg)rotateY(-15deg);
}

.loader .spinner:before, .loader .spinner:after {
  content: "";
  width: var(--orbit-diameter);
  height: var(--orbit-diameter);
  border-bottom-right-radius: var(--orbit-diameter);
  transform-origin: 0 0;
  opacity: .5;
  position: absolute;
  left: 50%;
}

.loader .spinner:before {
  border-bottom: 2px solid;
  animation: 4s linear infinite orbit;
}

.loader .spinner:after {
  bottom: calc(var(--orbit-diameter) * -1);
  border-right: 2px solid;
  animation: 4s linear .75s infinite reverse orbit;
}

.page:not(.loading) .loader {
  display: none;
}

@font-face {
  font-family: Inter;
  src: url("Inter-VariableFont_slnt,wght.81753b19.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Crimson Pro;
  src: url("CrimsonPro-VariableFont_wght.78a33766.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: Rammetto One;
  src: url("RammettoOne-Regular.2a6d116e.ttf") format("truetype");
  font-display: swap;
}

:root {
  --logo-width: 8rem;
  --logo-height: 6rem;
  --banner-height: var(--logo-height);
  --content-max-width: 30rem;
  --content-hrz-padding: 5rem;
  --content-vrt-padding: 3rem;
  --content-padding: var(--content-vrt-padding) var(--content-hrz-padding);
  --slide-aspect-ratio: calc(16 / 9);
  --slide-width: calc(var(--content-max-width)  + var(--content-hrz-padding) * 2);
  --slide-height: calc(var(--slide-width) / var(--slide-aspect-ratio));
  --slide-peek: 1rem;
  --slide-to-page-vrt-padding: calc(50vh - var(--slide-height) / 2);
  --slide-to-slide-vrt-gap: calc(var(--slide-to-page-vrt-padding)  - var(--slide-peek));
  --slide-scroll-distance: calc(var(--slide-height)  + var(--slide-to-slide-vrt-gap));
  --scroll-duration: 1s;
  --scroll-lagging-duration: calc(var(--scroll-duration) * 1.5);
  --scroll-easing: cubic-bezier(.32, 1.32, .32, 1);
  --scroll-transition: transform var(--scroll-duration) var(--scroll-easing);
  --scroll-lagging-transition: transform var(--scroll-lagging-duration) var(--scroll-easing);
  --sky-blue-hue: 240deg;
  --sky-teal-hue: 184deg;
  --sky-orange-hue: 24deg;
  --sky-teal: var(--sky-teal-hue) 80% 24%;
  --sky-blue: var(--sky-blue-hue) 80% 24%;
  --sky-orange: var(--sky-orange-hue) 88% 64%;
  --sky-gradients: radial-gradient( 160% 80% at 40% 0, hsl(var(--sky-teal) / 0), hsl(var(--sky-teal) / 16%) 40%, hsl(var(--sky-orange)) 160% ), linear-gradient( 240deg, hsl(var(--sky-blue) / 80%), hsl(var(--sky-teal) / 24%) 40% ), linear-gradient( 160deg, hsl(var(--sky-blue)), hsl(var(--sky-teal) / 24%) 40% ), linear-gradient( 0deg, hsl(var(--sky-teal)), hsl(var(--sky-teal)) );
  --scaling-factor: 1;
  font-size: calc(16px * var(--scaling-factor));
  background-color: #0c2d6e;
}

@media (any-pointer: coarse) {
  :root {
    --scaling-factor: 1.125;
  }
}

.page {
  background-image: var(--sky-gradients);
}

.page.home {
  row-gap: var(--content-vrt-padding);
  grid: ".        .         .         .         .         " 1fr ".        .         header    .         .         " var(--banner-height) ".        slide     slide     slide     nav       " var(--slide-height) ".        .         actions   .         .         " var(--banner-height) ".        .         .         .         .         " 1fr / 1fr var(--content-hrz-padding) var(--content-max-width) var(--content-hrz-padding) 1fr;
  display: grid;
}

html, body {
  overscroll-behavior: none;
  position: fixed;
  inset: 0;
  overflow: clip;
}

.header {
  mix-blend-mode: soft-light;
  grid-area: header;
  justify-content: center;
  align-items: end;
  display: flex;
}

.logo {
  filter: opacity(80%) drop-shadow(0 0 2px hsl(var(--sky-teal-hue) 80% 80% / 20%) );
  pointer-events: none;
  -webkit-user-select: none;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  display: flex;
  position: relative;
}

.logo .half {
  width: calc((var(--logo-width) / 2)  + 0rem);
  height: var(--logo-height);
  display: flex;
  overflow: hidden;
}

.slideshow {
  row-gap: inherit;
  grid: inherit;
  grid-area: 1 / 1 / -1 / -1;
  display: grid;
}

.slideshow:focus {
  outline: none;
}

.slideshow .slides {
  counter-reset: slide-number;
  z-index: 1;
  row-gap: var(--slide-to-slide-vrt-gap);
  perspective: 60cm;
  grid-area: slide;
  grid-auto-rows: 100%;
  display: grid;
  position: relative;
}

.slideshow :where(.nav .link, .slide, .slide .icon) {
  --scroll-position-y-base: calc(var(--active-slide-index) * var(--scroll-distance-y) * -1);
  --scroll-position-y-bump: calc(var(--scroll-bump-factor) * var(--slide-height) * -.15);
  --scroll-position-y: calc(var(--scroll-position-y-base)  + var(--scroll-position-y-bump));
  --scroll-position-z: calc(var(--slide-offset-abs) * -4cm);
  --scroll-rotation-x-base: calc(var(--slide-offset) * 30deg);
  --scroll-rotation-x-bump: calc(var(--scroll-bump-factor) * 15deg);
  --scroll-rotation-x: calc(var(--scroll-rotation-x-base)  + var(--scroll-rotation-x-bump));
  transform: translate3d(0, var(--scroll-position-y), var(--scroll-position-z)) rotateX(clamp(-90deg, var(--scroll-rotation-x), 90deg) );
}

.slideshow .slide {
  --slide-offset: calc(var(--active-slide-index)  - var(--slide-index));
  --slide-offset-abs: max(var(--slide-offset), -1 * var(--slide-offset));
  --slide-corner-radius: 2rem;
  --slide-background-color: hsl(var(--sky-orange-hue) 80% 80% / 8%);
  --slide-visual-width: calc((var(--slide-width)  - var(--logo-width)) / 2 + var(--content-hrz-padding));
  --slide-photo-saturate: 0%;
  --slide-photo-contrast: 160%;
  --slide-photo-brightness: 120%;
  --slide-photo-opacity: 80%;
  --slide-photo-size: cover;
  --slide-icon-size: 7.5rem;
  --scroll-distance-y: var(--slide-scroll-distance);
  border-radius: var(--slide-corner-radius);
  box-shadow: inset 1px 1px 1px hsl(var(--sky-teal-hue) 80% 80% / 8%), inset 1px 1px 1rem hsl(var(--sky-teal-hue) 80% 80% / 8%), 1px 1px 1px hsl(var(--sky-teal-hue) 80% 16% / 8%), 2rem 2rem 6rem hsl(var(--sky-blue-hue) 40% 8% / 32%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  transition: var(--scroll-transition);
  flex-direction: row-reverse;
  display: flex;
  position: relative;
}

.slideshow .slide:before {
  counter-increment: slide-number;
  content: counter(slide-number, decimal-leading-zero);
  width: var(--slide-visual-width);
  height: 0;
  padding-right: var(--content-hrz-padding);
  text-align: right;
  color: #fff;
  opacity: .04;
  transition: height var(--scroll-lagging-duration) var(--scroll-easing);
  font-family: Rammetto One, sans-serif;
  font-size: 4rem;
  line-height: 1.2em;
  position: absolute;
  bottom: calc(100% + 1px);
  right: 0;
  overflow: hidden;
}

.slideshow .slide:after {
  content: "";
  border-radius: inherit;
  border: 1px solid hsl(var(--sky-teal-hue) 80% 80% / 16%);
  background: hsl(var(--sky-teal-hue) 80% 80% / 16%);
  position: absolute;
  inset: 0;
  -webkit-mask: radial-gradient(100% 80% at 0 0, #fff, #0000 50%);
}

.slideshow .slide.active:before {
  height: .8em;
}

.slideshow .slide.active ~ .slide {
  transform-origin: top;
}

.slideshow .slide:has( ~ .active) {
  transform-origin: bottom;
}

.slideshow .slide .visual {
  contain: strict;
  inline-size: var(--slide-visual-width);
  block-size: 100%;
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
  flex-shrink: 0;
  position: absolute;
}

.slideshow .slide .visual .photo {
  inline-size: 100%;
  block-size: 100%;
  object-fit: cover;
  filter: saturate(var(--slide-photo-saturate)) contrast(var(--slide-photo-contrast)) brightness(var(--slide-photo-brightness)) opacity(var(--slide-photo-opacity));
  pointer-events: none;
  -webkit-user-select: none;
  -webkit-mask-image: radial-gradient(circle farthest-side at 100%, #000c, #0000003d, #0000 88%);
}

.slideshow .slide .visual .icon {
  --scroll-position-y-base: calc(var(--slide-offset) * var(--slide-icon-size) * -1);
  contain: strict;
  top: calc(50% - var(--slide-icon-size) / 2);
  left: calc((var(--slide-visual-width)  - var(--slide-icon-size)  + 5rem) / 2);
  inline-size: var(--slide-icon-size);
  block-size: var(--slide-icon-size);
  filter: drop-shadow(-.125rem -.125rem .5rem hsl(var(--sky-orange-hue) 64% 64% / 48%) ) drop-shadow(.625rem .625rem .4rem hsl(var(--sky-teal-hue) 64% 8% / 64%) );
  transition: var(--scroll-lagging-transition);
  pointer-events: none;
  -webkit-user-select: none;
  position: absolute;
}

.slideshow .slide .visual .credit {
  inline-size: 100%;
  text-align: center;
  color: #fff;
  writing-mode: vertical-lr;
  filter: drop-shadow(0 0 1px hsl(var(--sky-teal-hue) 100% 8% / 100%) );
  opacity: .25;
  padding-block: .5em;
  padding-inline: 2rem;
  font-family: Inter, sans-serif;
  font-size: .5rem;
  line-height: 1.5em;
  position: absolute;
  top: 0;
  right: 0;
}

.slideshow .slide .visual .credit a {
  color: inherit;
  text-decoration: none;
}

.slideshow .slide .visual .credit a:hover {
  text-decoration: underline;
}

.slideshow .slide .visual .credit .separator {
  vertical-align: bottom;
  padding-inline: .25em;
  font-size: 2em;
}

.slideshow .slide .text {
  contain: content;
  inline-size: 100%;
  max-inline-size: 27rem;
  block-size: 100%;
  padding: var(--content-padding);
  font-variation-settings: "wght" 450;
  hyphens: manual;
  color: hsl(var(--sky-teal-hue) 80% 8% / 80%);
  align-items: center;
  margin-right: auto;
  padding-right: 0;
  font-family: Crimson Pro, serif;
  font-size: 1.125rem;
  line-height: 1.5em;
  display: flex;
}

.nav {
  --nav-link-height: 1em;
  counter-reset: slide-number;
  color: #e3dedee0;
  mix-blend-mode: overlay;
  grid-area: nav;
  margin-left: 1rem;
  font-size: .875rem;
  container: nav / inline-size;
}

.nav:before {
  content: "";
  height: calc(50% - var(--nav-link-height) / 2);
  display: block;
}

.nav .link {
  --slide-offset: calc(var(--active-slide-index)  - var(--slide-index));
  --slide-offset-abs: max(var(--slide-offset), -1 * var(--slide-offset));
  --scroll-distance-y: var(--nav-link-height);
  counter-increment: slide-number;
  height: var(--nav-link-heigh);
  font-variation-settings: "wght" 850;
  letter-spacing: .04em;
  font-family: Inter, sans-serif;
  line-height: var(--nav-link-height);
  text-transform: uppercase;
  color: inherit;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  opacity: calc(1 / (1 + var(--slide-offset-abs) * 3) );
  transition: opacity var(--scroll-lagging-duration) var(--scroll-easing), transform var(--scroll-duration) var(--scroll-easing);
  outline: none;
  align-items: center;
  text-decoration: none;
  display: flex;
  overflow: hidden;
}

.nav .link:before {
  content: counter(slide-number, decimal-leading-zero) "/";
  opacity: .4;
}

.nav .link:after {
  content: "";
  height: .8em;
  opacity: .4;
  background: linear-gradient(to right, #fff6, #0000);
  flex-grow: 1;
  margin-left: .1em;
}

.nav .link.active ~ .link {
  transform-origin: top;
}

.nav .link:has( ~ .link.active) {
  transform-origin: bottom;
}

.nav .separator {
  display: none;
}

@container nav (max-width: 8rem) {
  .nav .link {
    display: none;
  }
}

.actions {
  mix-blend-mode: overlay;
  grid-area: actions;
  justify-content: space-between;
  align-items: start;
  padding-block: .5rem;
  display: flex;
}

.actions .action {
  --key-icon-width: 2.5rem;
  --key-icon-height: 2.75rem;
  font-variation-settings: "wght" 600;
  letter-spacing: .04em;
  text-transform: uppercase;
  color: #fff;
  opacity: .32;
  align-items: flex-end;
  gap: .8em;
  font-family: Inter, sans-serif;
  font-size: .625rem;
  line-height: 1.1em;
  text-decoration: none;
  transition: opacity .4s ease-out;
  display: flex;
}

.actions .action:before {
  content: attr(data-hotkey);
  width: var(--key-icon-width);
  height: var(--key-icon-height);
  font-variation-settings: "wght" 600;
  text-align: center;
  color: hsl(var(--sky-blue) / 60%);
  background: url("keyboard-key.5a47b82d.svg") 0 0 / cover;
  flex-shrink: 0;
  margin-bottom: -.2em;
  padding-top: .4em;
  font-size: 1.2em;
}

.actions .action:hover, .actions .action.active {
  opacity: .8;
  filter: drop-shadow(0 0 4px #ffffff40);
}

.actions .action:hover:before {
  color: hsl(var(--sky-blue) / 40%);
}

.actions .action:active:before, .actions .action.active:before {
  transform: translateY(12%);
}

.actions .action.email {
  width: 11em;
}

.actions .action.resume {
  width: 12em;
  text-align: right;
  flex-direction: row-reverse;
}

.stars {
  contain: content;
  z-index: -1;
  opacity: .4;
  mix-blend-mode: screen;
  pointer-events: none;
  transform: translateY(calc(var(--active-slide-index) * -.75rem) );
  transition: var(--scroll-transition);
  background: url("stars.0b16841b.svg");
  position: fixed;
  inset: 0;
  -webkit-mask-image: linear-gradient(#000, #0000);
}

.stars:before {
  content: "";
  opacity: .25;
  background: url("noise.5237b4c5.png") 0 0 / 5rem;
  position: absolute;
  inset: 0;
}

@keyframes intro-stars {
  from {
    opacity: 0;
    scale: 3;
  }

  to {
    opacity: .4;
    scale: 1;
  }
}

@keyframes intro-nav {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes intro-slides {
  from {
    transform: translateY(80vh);
  }
}

@keyframes intro-logo-type {
  from {
    translate: 150%;
  }
}

@keyframes intro-logo-mark {
  from {
    translate: -150%;
  }
}

@keyframes intro-actions {
  from {
    opacity: 0;
    transform: translateY(160vh);
  }
}

:root {
  --intro-animation-duration: 1.5s;
  --intro-animation-timing-function: cubic-bezier(.32, 1, .32, 1);
}

@media (prefers-reduced-motion) {
  :root {
    --intro-animation-duration: 0s;
  }
}

.stars, .nav, .slides, .logo .type, .logo .mark, .actions {
  animation-duration: var(--intro-animation-duration);
  animation-timing-function: var(--intro-animation-timing-function);
}

.page.loading :is(.stars, .nav, .slides, .logo .type, .logo .mark, .actions) {
  animation-play-state: paused;
}

.stars {
  animation-name: intro-stars;
}

.nav {
  animation-name: intro-nav;
}

.slides {
  animation-name: intro-slides;
}

.logo .type {
  animation-name: intro-logo-type;
}

.logo .mark {
  animation-name: intro-logo-mark;
}

.actions {
  animation-name: intro-actions;
}

@keyframes shooting-meteor {
  0% {
    opacity: 0;
    transform: translate(0%)scaleX(0);
  }

  60% {
    opacity: 1;
    transform: translate(100%, 100%)scaleX(1);
  }

  90% {
    opacity: .5;
    transform: translate(200%, 200%)scaleX(0);
  }

  100% {
    opacity: 0;
    transform: translate(200%, 200%)scaleX(0);
  }
}

.meteor {
  --meteor-rotation-angle: 25deg;
  width: 120px;
  height: 1px;
  background: linear-gradient(var(--meteor-rotation-angle), transparent, white);
  rotate: var(--meteor-rotation-angle);
  transform-origin: 0 0;
  opacity: 0;
  filter: drop-shadow(0 0 4px #fff);
  mix-blend-mode: overlay;
  border-radius: 1px;
  position: fixed;
  transform: translate(0)scaleX(0);
}

.page:not(.loading) .meteor.is-shooting {
  animation: 1s linear shooting-meteor;
}

@media screen and (max-width: 48em), screen and (max-height: 48em) {
  :root {
    --banner-height: calc(50vh - var(--content-vrt-padding) * 2);
    --content-max-width: min(16rem, 100% - (var(--content-hrz-margin)  + var(--content-hrz-padding)) * 2);
    --content-hrz-margin: .75rem;
    --content-hrz-padding: 1.5rem;
    --content-vrt-padding: var(--content-hrz-padding);
    --slide-height: auto;
    --slide-to-slide-vrt-gap: 8rem;
  }

  html, body {
    position: absolute;
    overflow-y: auto;
  }

  .header, .actions {
    align-items: center;
    position: sticky;
  }

  .nav {
    display: none;
  }

  .slideshow {
    display: revert;
    grid-area: slide;
  }

  .slideshow .slides {
    grid-auto-rows: revert;
  }

  .slideshow .slide {
    --target-scroll-progress: .5;
    flex-direction: column;
  }

  .slideshow .slide:before {
    width: auto;
    height: .8em;
  }

  .slideshow .slide .visual {
    --slide-visual-width: 12rem;
    writing-mode: vertical-lr;
    direction: rtl;
    position: relative;
  }

  .slideshow .slide .visual .photo {
    -webkit-mask-image: radial-gradient(circle farthest-side at top, #000c, #0000003d, #0000);
  }

  .slideshow .slide .visual .icon {
    left: calc(50% - var(--slide-icon-size) / 2);
    transform: translateY(calc(var(--target-scroll-progress) * 30%) );
    transition: transform linear;
  }

  .slideshow .slide .visual .credit {
    display: none;
  }

  .slideshow .slide .text {
    padding-top: 0;
    padding-right: var(--content-hrz-padding);
  }

  .stars {
    z-index: revert;
    transform: translateY(calc(var(--viewport-scroll-progress) * -15%) );
    transition: transform linear;
  }
}

/*# sourceMappingURL=path0.52c63e03.css.map */
